import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

import { Accordion } from '@/components/Accordion'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { AwardRecipientAll } from './AwardRecipientAll'
import { AwardSpecialHeading } from './AwardSpecialHeading'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?:
    | readonly (
        | Queries.AwardRecipientAllFragment
        | Queries.AwardSpecialHeadingFragment
        | null
      )[]
    | null
  bgColor: 'WHITE' | 'GRAY'
}

export const AllRecipients = ({
  data,
  bgColor,
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      padding: 0.5em 0 0;
      margin: 0 var(--margin);
      ${bgColor === 'WHITE' &&
      css`
        border-top: 2px solid ${colors.gray85};
      `}
      ${bgColor === 'GRAY' &&
      css`
        border-top: 2px solid ${colors.gray45};
        color: #fff;
      `}
    `,
    accordion: css`
      ${bgColor === 'GRAY' &&
      css`
        --text-color: #fff;
        --button-color: ${colors.gray75};
        --hover-color: ${colors.redLight};
      `}
    `,
    heading: css``,
    content: css`
      width: 100%;
      columns: 4;
      gap: 2em;
      list-style: none;
      margin: 0;
      padding: 1.5em 0 0;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-block;
      }
      ${mq().m} {
        columns: 3;
      }
      ${mq().ms} {
        columns: 2;
      }
      ${mq().s} {
        columns: 1;
      }
    `,
  }

  const recipientsByYear = [...(data || [])]
    ?.sort(
      (a, b) =>
        parseInt(a?.awardYear || '') - parseInt(b?.awardYear || '')
    )
    .reduce(
      (acc, item) => {
        const matchingYear = acc.find(
          val => val.year === item?.awardYear
        )
        if (matchingYear) {
          matchingYear.items.push(item)
        } else {
          acc.push({ year: item?.awardYear, items: [item] })
        }
        return acc
      },
      [] as {
        year?: string | null
        items: (
          | Queries.AwardRecipientAllFragment
          | Queries.AwardSpecialHeadingFragment
          | null
        )[]
      }[]
    )
  return (
    <section
      css={styles.section}
      {...props}
    >
      <Accordion
        css={styles.accordion}
        data={{
          items: [
            {
              heading: <h3 css={styles.heading}>All Recipients</h3>,
              content: (
                <ul css={styles.content}>
                  {recipientsByYear?.map(({ year, items }, i) => (
                    <li key={i}>
                      <ul aria-label={`${year}`}>
                        {items.map((item, ii) => {
                          switch (item?.__typename) {
                            case 'DatoCmsAwardRecipientAll':
                              return (
                                <li key={ii}>
                                  <AwardRecipientAll
                                    data={item}
                                    showYear={ii === 0}
                                    key={ii}
                                    bgColor={bgColor}
                                  />
                                </li>
                              )
                            case 'DatoCmsAwardSpecialHeading':
                              return (
                                <li key={ii}>
                                  <AwardSpecialHeading
                                    data={item}
                                    showYear={ii === 0}
                                    key={ii}
                                  />
                                </li>
                              )
                          }
                        })}
                      </ul>
                    </li>
                  ))}
                </ul>
              ),
            },
          ],
        }}
      />
    </section>
  )
}
